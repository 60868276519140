import React from "react";

function Couch() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
        >
            <mask
                id="mask0_0_261"
                style={{ maskType: "alpha" }}
                width="80"
                height="80"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H80V80H0z"></path>
            </mask>
            <g fill="#244880" mask="url(#mask0_0_261)">
                <path d="M74.828 29.976c-2.858 0-5.176 2.308-5.176 5.153 0 1.365.538 2.604 1.41 3.526v7.51H8.883V38.55a5.122 5.122 0 001.47-3.592c0-2.846-2.319-5.153-5.177-5.153C2.318 29.804 0 32.11 0 34.957c0 .191.012.38.034.568-.009.11-.034.214-.034.325v15.107c0 .135.028.258.039.389-.007.119-.039.23-.039.347 0 3.053 2.796 5.527 6.246 5.527h1.953v6.31h3.074l1.489-6.31h53.99l1.492 6.31h3.073v-6.31h2.377c3.448 0 6.246-2.474 6.246-5.527 0-.117-.03-.23-.04-.344.01-.133.04-.257.04-.392V35.872a5.2 5.2 0 00.06-.743c.003-2.845-2.314-5.152-5.172-5.152z"></path>
                <path d="M69.02 39.89c0 2.658-2.095 4.816-4.681 4.816H15.661c-2.585 0-4.68-2.157-4.68-4.817V22.071c0-2.66 2.095-4.816 4.68-4.816h48.678c2.586 0 4.68 2.156 4.68 4.816v17.818z"></path>
            </g>
        </svg>
    );
}

export default Couch;
