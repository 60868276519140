import React from "react";

function PotentialInjury() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
        >
            <mask
                id="mask0_0_265"
                style={{ maskType: "alpha" }}
                width="80"
                height="80"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H80V80H0z"></path>
            </mask>
            <g mask="url(#mask0_0_265)">
                <path
                    fill="#244880"
                    d="M71.817 38.62a1.38 1.38 0 100 2.76H80C79.284 62.36 62.359 79.284 41.38 80v-8.183a1.38 1.38 0 10-2.76 0V80C17.64 79.284.714 62.359 0 41.38h8.183a1.38 1.38 0 100-2.76H0C.716 17.64 17.641.716 38.62 0v8.183a1.38 1.38 0 102.76 0V0C62.36.716 79.284 17.641 80 38.62h-8.183zm-19.76 13.54L41.38 39.496V20.744a1.38 1.38 0 10-2.762 0V40c0 .326.115.64.325.89l11.001 13.05a1.378 1.378 0 001.945.166 1.38 1.38 0 00.167-1.945z"
                ></path>
            </g>
        </svg>
    );
}

export default PotentialInjury;
