import React from "react";

function DollarSign() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
        >
            <mask
                id="mask0_0_256"
                style={{ maskType: "alpha" }}
                width="80"
                height="80"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H80V80H0z"></path>
            </mask>
            <g fill="#244880" mask="url(#mask0_0_256)">
                <path d="M77.643 39.742c.296 20.293-16.17 36.895-36.452 36.895-16.24 0-30.03-10.649-34.728-25.312a.462.462 0 00-.556-.31l-2.384.616a.933.933 0 01-1.11-1.228l3.613-9.643a.936.936 0 011.483-.382l7.849 6.685a.93.93 0 01-.373 1.61l-2.721.703a.473.473 0 00-.332.604c4.082 12.224 15.65 21.064 29.259 21.064 17.583 0 31.776-14.747 30.8-32.49-.824-14.976-12.64-27.356-27.602-28.876-14.492-1.472-27.677 7.193-32.312 20.403-1.26 3.591-6.511 1.658-5.33-1.743C12.186 12.67 27.786 2.38 44.941 4.11c18.256 1.841 32.434 17.333 32.7 35.632l.001-.002z"></path>
                <path d="M41.031 15.783c-13.366 0-24.238 10.873-24.238 24.239 0 13.365 10.873 24.238 24.238 24.238 13.366 0 24.239-10.873 24.239-24.239 0-13.365-10.873-24.238-24.239-24.238zm-4.66 21.442h8.993c4.96 0 9.299 3.734 9.63 8.684.363 5.428-3.95 9.96-9.301 9.96h-1.398a.466.466 0 00-.467.467v2.33a.933.933 0 01-.932.933h-3.729a.933.933 0 01-.932-.932v-2.331a.466.466 0 00-.467-.466H36.37c-4.786 0-8.742-3.626-9.264-8.275a.942.942 0 01.933-1.048h3.769c.453 0 .821.33.914.773a3.735 3.735 0 003.648 2.957h9.157c1.946 0 3.688-1.42 3.877-3.356a3.734 3.734 0 00-3.711-4.102H36.7c-4.96 0-9.3-3.735-9.63-8.685-.364-5.428 3.949-9.96 9.3-9.96h1.399a.466.466 0 00.466-.467v-2.33c0-.515.417-.933.932-.933h3.729c.514 0 .932.418.932.933v2.33c0 .258.21.466.467.466h1.398c4.786 0 8.742 3.626 9.264 8.275a.942.942 0 01-.933 1.048h-3.769c-.453 0-.821-.33-.914-.773a3.735 3.735 0 00-3.648-2.956h-9.157c-1.946 0-3.688 1.419-3.877 3.356a3.734 3.734 0 003.711 4.102z"></path>
            </g>
        </svg>
    );
}

export default DollarSign;
