import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

import Couch from "./icons/Couch";
import Clock from "./icons/Clock";
import DollarSign from "./icons/DollarSign";

const benefitsList = [
    {
        title: "Your Budget",
        description:
            "Start by determining your maximum affordable spend. Then, add some flexibility for unforeseen expenses or delays, to ensure a more realistic budget.",
        icon: <Clock />,
    },
    {
        title: "Custom Items",
        description:
            "If you have unique, heavy, or delicate items such as custom furniture or pianos, it's important to choose movers with expertise in handling these items.",
        icon: <Couch />,
    },
    {
        title: "Timeline",
        description:
            "Faster movers might be limited by tight schedules, while having more time for your move broadens your choice of companies.",
        icon: <DollarSign />,
    },
];

export default function Benefits(): ReactElement {
    return (
        <div className={`max-w-7xl mx-auto  ${styles["container"]}`}>
            <h2
                className={`text-center font-bold text-xl capitalize lg:text-2xl mb-14  ${styles["mainTitle"]}`}
            >
                How to Choose the Right Moving Company for You
            </h2>
            <p className={`text-center text-lg capitalize lg:text-xl mb-14  `}>
                Selecting the right moving company is crucial for a smooth
                relocation experience. When researching, consider factors such
                as:
            </p>
            <div className="flex flex-col items-center lg:flex-row gap-4 justify-between px-4 text-center">
                {benefitsList.map((item, index) => (
                    <div
                        key={index}
                        className={`w-full flex flex-col justify-center align-center lg:w-[30%] py-11 px-6 gap-6 lg:gap-11 max-w-[350px] ${styles["item"]}`}
                    >
                        <span className="mx-auto mb-[14px]">{item.icon}</span>
                        <h3 className="font-bold text-base lg:text-lg capitalize">
                            {item.title}
                        </h3>
                        <p className="font-normal text-sm">
                            {item.description}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}
